<template>
  <v-menu :close-on-content-click="false" v-model="menu" content-class="chat-down__modal">
    <template v-slot:activator="{ on, attrs }">
      <div class="chat-down__filters" v-bind="attrs" v-on="on">
        Фильтр
        <img class="ml-1" alt="filter" :src="require('@/assets/img/chat/filter.svg')"/>
      </div>
    </template>
    <div class="pa-5 chat-down__filter-window">
      <v-autocomplete
          class="evi-autocomplete mb-5"
          color="#44D370"
          :items="statuses"
          v-model="clientStatusFilter"
          item-text="name"
          item-value="id"
          clearable
          item-color="green"
          hide-details
      >
        <template v-slot:label>
          Фильтровать по статусу
        </template>
        <template v-slot:append>
                        <span class="evi-autocomplete__arrow">
                            <v-icon>mdi-chevron-down</v-icon>
                        </span>
        </template>
        <template v-slot:selection="{item}">
          <div class="modal-create__color-select">
            <span class="color" :style="{backgroundColor: `${item.color}`}"/>
            {{ item.name }}
          </div>
        </template>
        <template v-slot:item="{item}">
          <div class="modal-create__color-select">
            <span class="color mt-1" :style="{backgroundColor: `${item.color}`}"/>
            {{ item.name }}
          </div>
        </template>
      </v-autocomplete>
      <v-autocomplete
          class="evi-autocomplete"
          color="#44D370"
          :items="tags"
          v-model="tagFilter"
          item-text="name"
          item-value="id"
          clearable
          item-color="green"
          multiple
          hide-details
      >
        <template v-slot:label>
          Фильтровать по тегам
        </template>
        <template v-slot:append>
            <span class="evi-autocomplete__arrow">
                <v-icon>mdi-chevron-down</v-icon>
            </span>
        </template>
        <template v-slot:selection="{item}">
          <div class="evi-autocomplete__tag mr-2 mt-2"
               :style="{backgroundColor: `${item.color}`, color: `${item.text_color}` }">
            {{ item.name }}
          </div>
        </template>
        <template v-slot:item="{item}">
          <div class="evi-autocomplete__tag"
               :style="{backgroundColor: `${item.color}`, color: `${item.text_color}` }">
            {{ item.name }}
          </div>
        </template>
      </v-autocomplete>
    </div>
  </v-menu>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "ChatFilter",
  data: () => ({
    menu: false,
    tagFilter: null,
    clientStatusFilter: null,
  }),
  computed: {
    ...mapState("tags", ["tags",]),
    ...mapState("client-statuses", ["statuses",]),
  },
  methods: {
    update () {
      this.$emit('update', {
        'filter[client.tags.id]': this.tagFilter,
        'filter[client.clientStatus.id]': this.clientStatusFilter,
      });
    }
  },
  watch: {
    tagFilter () {
      this.update();
    },
    clientStatusFilter () {
      this.update();
    },
    tab () {
      this.tagFilter = ''
      this.clientStatusFilter = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-down__filter-window {
  max-width: 350px;
}
</style>
