<template>
  <div class="chat-dialogs" id="chat-dialogs" @scroll="handleScroll">
    <div class="chat-dialogs__item" :class="{'chat-dialogs__active' : isActiveChat(i)}" v-for="i in dialogs"
         :key="i.id" @click="toChat(i)">
      <v-avatar
          color="#FFFFFF"
          size="45"
          class="mr-2"
      >
        <img
            :src="chatImage(i) || require('@/assets/img/users/profile-avatar.png')"
            alt=""
        >
      </v-avatar>
      <div class="chat-dialogs__info" v-if="i">
        <div class="d-flex align-baseline">
          <div class="chat-dialogs__companion mr-2">
            {{ chatName(i) }}
            <img class="ml-1 int-type" width="20"
                 v-if="i.client && i.client.integration"
                 :src="require(`@/assets/img/integrations/${i.client.integration.type}.svg`)"
                 alt=""/>
            <img class="ml-1 int-type" width="20"
                 v-else-if="i.client && i.client.integration_type"
                 :src="require(`@/assets/img/integrations/${i.client.integration_type}.svg`)"
                 alt=""/>
          </div>
          <div class="chat-dialogs__client-status text-truncate" v-if="i.client && i.client.client_status" :style="{color: `${i.client.client_status.color}` }">
            {{i.client.client_status.name}}
          </div>
        </div>
        <div v-if="i.last_message" class="chat-dialogs__message">
          <div class="chat-dialogs__message-text" v-if="i.last_message.message">
            <span class="chat-dialogs__message-author" v-if="i.last_message.user">{{sender(i.last_message.user)}}:</span>
            {{ i.last_message.message }}
          </div>
          <div class="blue--text chat-dialogs__message-text" v-else>
            <span class="chat-dialogs__message-author" v-if="i.last_message.user">{{sender(i.last_message.user)}}:</span>
            Вложение
          </div>
        </div>
        <div class="d-flex mt-2 flex-wrap" v-if="i.client">
          <div class="chat-dialogs__tag" v-for="tag in i.client.tags" :key="tag.id" :style="{backgroundColor: `${tag.color}`, color: `${tag.text_color}` }">
            {{tag.name}}
          </div>
        </div>
      </div>
      <div v-if="i && i.last_message" class="chat-dialogs__date">
        <small>{{ returnChatTime(i.last_message.created_at)}}</small>
        <span v-if="i.unread_messages_count">{{ i.unread_messages_count }}</span>
        <span v-else-if="ownLastMessage(i.last_message)" class="chat-dialogs__own-message"></span>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Dialogs",
  props: {
    dialogs: {
      type: Array,
    },
    newChats: {
      type: Array,
    },
    chatsLoading: {
      type: Boolean,
    }
  },
  computed: {
    ...mapState("user", ["profile"]),
  },
  methods: {
    chatName(chat) {
      if (chat.subtype === "group") {
        //беседа
        return chat.name;
      } else {
        if (chat.client) {
          return chat.client.name;
        } else if (chat.chat && chat.chat.client) {
          return chat.chat.client.name;
        } else if (chat.users) {
          let user = chat.users.find(i => i.id !== this.profile.id);
          if (user) return user.name;
        }
      }
    },
    returnChatTime (date) {
      if (this.$moment().format("MMM Do YY") === this.$moment(date).format("MMM Do YY")) {
        return this.$moment(date).format('LT');
      } else if (this.$moment(date).format('YYYY') !== this.$moment().format("YYYY") ) {
        return this.$moment(date).format('DD MMM YYYY');
      }
      return this.$moment(date).format('DD MMM');
    },
    sender (user) {
      if (user.id === this.profile.id) {
        return 'Вы';
      } else {
        return user.name.split(' ')[0];
      }
    },
    handleScroll(e) {
      if (e.target.scrollHeight - e.target.scrollTop - e.target.scrollHeight / 2 <= e.target.clientHeight && !this.chatsLoading) {
        this.$emit('chatsLoad');
        let oldScrollPos = e.target.scrollTop;
        this.$emit('updatePages');
        this.$nextTick(() => {
          e.target.scrollTop = oldScrollPos;
        });
      }
    },
    ownLastMessage (m) {
      return m.user && m.user.id === this.profile.id;
    },
    toChat(chat) {
      if (chat.chat_id) {
        chat.id = chat.chat_id;
      }
      if (chat.subtype === "group") {
        //беседа
        return this.$router.push({path: `/chat/users/${chat.id}`}).catch(() => {
        });
      } else {
        if (chat.client) {
          return this.$router.push({path: `/chat/clients/${chat.id}`}).catch(() => {
          });
        } else {
          return this.$router.push({path: `/chat/users/${chat.id}`}).catch(() => {
          });
        }
      }
    },
    chatImage(i) {
      if (i.subtype === "group") {
        return require('@/assets/img/chat/group.png');
      } else {
        if (i.client) {
          return i.client.avatar_url;
        } else if (i.chat && i.chat.client) {
          return i.chat.client.avatar_url;
        } else if (i.users) {
          let user = i.users.find(i => i.id !== this.profile.id);
          if (user) return user.avatar_url;
        }
      }
    },
    isActiveChat(i) {
      let currentChatId = parseInt(this.$route.params.id);
      if (i && currentChatId) {
        return currentChatId === i.id || this.$route.params.id === i.chat_id;
      }
      return false;
    },
  }
}
</script>

<style lang="scss" scoped>
.chat-dialogs {
  height: $evi-chat-dialogs-height;
  /*max-height: 80px;*/
  overflow-y: auto;
  margin-top: 10px;
  /*padding-bottom: 40px;*/

  &__client-status {
    font-size: 11px;
    font-weight: 700;
    max-width: 40%;
  }

  &__active {
    background: #F4F4F4 !important;
  }

  &__info {
    width: 100%;
    overflow: hidden;
  }

  &__item {
    padding: 12px 9px 12px 17px;
    display: flex;
    position: relative;
    cursor: pointer;

    .int-type {
      position: absolute;
      left: 9px;
      top: 4px;
    }
  }

  &__companion {
    font-weight: 600;
    font-size: 12px;
    color: $evi-headline-color;
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__message {
    font-weight: normal;
    font-size: 12px;
    color: $evi-grey-color;
    max-width: 295px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &-text {
      text-overflow: ellipsis;
      display: inline;
    }
    &-author {
      color: $evi-grey-color;
      opacity: 0.7;
    }
  }

  &__tag {
    font-weight: 600;
    font-size: 10px;
    border-radius: 2px;
    padding: 0 3px;
    margin-right: 4px;
    margin-bottom: 4px;
  }

  &__date {
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: flex-end;
    //position: absolute;
    //top: 24px;
    //right: 24px;

    small {
      font-weight: normal;
      font-size: 11px;
      color: $evi-grey-color;
      white-space: nowrap;
      //position: absolute;
      //top: 10px;
      //right: 24px;
    }

    span {
      font-weight: 500;
      font-size: 12px;
      background: #FF6553;
      min-height: 20px;
      min-width: 20px;
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin-top: 4px;
      padding: 1px 3px;
      position: absolute;
      top: 30px;
      right: 10px;
    }

    .chat-dialogs__own-message {
      background-color: #40BEAA;
      min-height: 10px;
      min-width: 10px;
      margin-right: 5px;
      margin-top: 9px;
    }
  }
}
</style>
