var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"content-class":"chat-down__modal"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"chat-down__filters"},'div',attrs,false),on),[_vm._v(" Фильтр "),_c('img',{staticClass:"ml-1",attrs:{"alt":"filter","src":require('@/assets/img/chat/filter.svg')}})])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('div',{staticClass:"pa-5 chat-down__filter-window"},[_c('v-autocomplete',{staticClass:"evi-autocomplete mb-5",attrs:{"color":"#44D370","items":_vm.statuses,"item-text":"name","item-value":"id","clearable":"","item-color":"green","hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Фильтровать по статусу ")]},proxy:true},{key:"append",fn:function(){return [_c('span',{staticClass:"evi-autocomplete__arrow"},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"modal-create__color-select"},[_c('span',{staticClass:"color",style:({backgroundColor: ("" + (item.color))})}),_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"modal-create__color-select"},[_c('span',{staticClass:"color mt-1",style:({backgroundColor: ("" + (item.color))})}),_vm._v(" "+_vm._s(item.name)+" ")])]}}]),model:{value:(_vm.clientStatusFilter),callback:function ($$v) {_vm.clientStatusFilter=$$v},expression:"clientStatusFilter"}}),_c('v-autocomplete',{staticClass:"evi-autocomplete",attrs:{"color":"#44D370","items":_vm.tags,"item-text":"name","item-value":"id","clearable":"","item-color":"green","multiple":"","hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Фильтровать по тегам ")]},proxy:true},{key:"append",fn:function(){return [_c('span',{staticClass:"evi-autocomplete__arrow"},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"evi-autocomplete__tag mr-2 mt-2",style:({backgroundColor: ("" + (item.color)), color: ("" + (item.text_color)) })},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"evi-autocomplete__tag",style:({backgroundColor: ("" + (item.color)), color: ("" + (item.text_color)) })},[_vm._v(" "+_vm._s(item.name)+" ")])]}}]),model:{value:(_vm.tagFilter),callback:function ($$v) {_vm.tagFilter=$$v},expression:"tagFilter"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }