<template>
  <div class="chat">
    <div class="chat-all-dialogs" v-if="true">
      <div class="chat-all-dialogs__header">
        <v-text-field
            v-if="isScripts"
            class="chat-search"
            color="#008A80"
            solo
            placeholder="Поиск по быстрым фразам"
            v-model="searchScript"
            clearable
        >
          <template v-slot:prepend-inner>
            <img :src="require('@/assets/img/chat/search.svg')" alt="">
          </template>
        </v-text-field>
        <v-text-field
            v-else
            class="chat-search"
            color="#44D370"
            solo
            placeholder="Поиск по чатам"
            v-model="search"
        >
          <template v-slot:prepend-inner>
            <img :src="require('@/assets/img/chat/search.svg')" alt="">
          </template>
        </v-text-field>
        <v-menu
            top
            offset-y
            open-on-hover
            max-height="156"
        >
          <template v-slot:activator="{ on, attrs }">
            <img v-bind="attrs" v-on="on" class="pointer" v-if="areChatsMuted" @click="mute"
                 :src="require('@/assets/img/chat/chats-not-muted.svg')" alt="">
            <img v-bind="attrs" v-on="on" class="pointer" v-else @click="mute"
                 :src="require('@/assets/img/chat/chats-muted.svg')" alt="">
          </template>
          <v-list-item v-if="areChatsMuted" class="user-color-status d-flex justify-space-between align-center">
            Звуковые оповещения выключены
          </v-list-item>
          <v-list-item v-else class="user-color-status d-flex justify-space-between align-center">
            Звуковые оповещения включены
          </v-list-item>
        </v-menu>
        <v-menu offset-y v-if="isUserChat">
          <template v-slot:activator="{ on, attrs }">
            <img class="pointer ml-3" v-on="on" v-bind="attrs" :src="require('@/assets/img/chat/edit.svg')" alt="">
          </template>
          <v-list dense class="profile-menu">
            <v-list-item-group>
              <v-list-item @click="createChat(false)">
                <v-list-item-icon class="evi-sidebar__icon">
                  <img width="16" :src="require(`@/assets/img/profile/profile.svg`)" alt=""/>
                </v-list-item-icon>
                <v-list-item-title>Начать диалог</v-list-item-title>
              </v-list-item>
              <v-list-item @click="createChat(true)">
                <v-list-item-icon class="evi-sidebar__icon">
                  <img width="16" :src="require(`@/assets/img/menu/clients.svg`)" alt=""/>
                </v-list-item-icon>
                <v-list-item-title>Создать беседу</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </div>
      <v-tabs
          v-model="tab"
          align-with-title
          class="chat-all-dialogs__tabs"
          :show-arrows="false"
      >
        <v-tab
            v-for="item in tabs"
            :key="item"
            @click="isScripts = false"
        >
          {{ item }}
          <span class="chat-has-reminders" v-if="item === 'Напоминания' && activeReminders"></span>
        </v-tab>
        <div class="d-flex ml-6">
          <v-tab class="mr-2" @click="isScripts = false">
            <img v-if="isPinnedChat" :src="require('@/assets/img/chat/pinned_active.svg')" width="21" alt="">
            <img v-else :src="require('@/assets/img/chat/pinned.svg')" width="21" alt="">
          </v-tab>
          <div class="open-scripts mt-4" @click="isScripts = !isScripts">
            <img v-if="isScripts" :src="require('@/assets/img/menu/scripts.svg')" width="20" alt="">
            <img v-else :src="require('@/assets/img/event-log/scriptgroup.svg')" width="20" alt="">
          </div>
        </div>
      </v-tabs>
      <div v-if="localLoading" class="chat-empty-all-dialogs">
        <v-skeleton-loader
            type="list-item-avatar-two-line"
            width="100%"
            height="100%"
            v-for="i in 30"
            :key="i"
        />
      </div>
      <div class="chat-empty-all-dialogs" v-if="!chats.length && showUnreadFilter && !localLoading">
        Диалоги не найдены
      </div>
      <div class="chat-reminders" v-else-if="tabs[tab] === 'Напоминания'">
        <div class="chat-reminders__headers">
          <div class="reminder-type" :class="{'active': rt.value === selectedReminder.value}"
               v-for="rt in reminderTypes" :key="rt.value" @click="selectedReminder = rt">
            {{ rt.title }}
            <v-badge
                :color="getReminder(rt.value).length ? '#FF6553' : '#BDBDBD' "
                :content="getReminder(rt.value).length || '0'"
                class="ml-2"
            >
            </v-badge>
          </div>
        </div>
        <div>
          <v-expansion-panels class="evi-accordion overflow-y-auto">
            <v-expansion-panel
                v-for="(item) in getReminder(selectedReminder.value)"
                :key="item.id"
            >
              <v-expansion-panel-header>
                <div class="d-flex justify-space-between align-center">
                  <div class="accordion-name text-truncate" @click="chat(item.client_id)">{{item.client_name}}</div>
                  <div class="accordion-info d-flex">
                    <div class="mr-7 text-right">
                      <div class="accordion-info__date">{{item.date | moment('DD.MM.YYYY')}}</div>
                      <div class="accordion-info__status text-truncate" :style="{color: `${item.client_status_color}`}">{{item.client_status_name}}</div>
                    </div>
                    <div class="close-dialog" @click="openRemoveReminderModal(item.id)">
                      <img width="14" :src="require('@/assets/img/common/close-dialog.svg')"/>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="accordion-text" v-if="item.description">{{item.description}}</div>
                <div v-else class="accordion-text grey--text">Написать клиенту</div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
      <Dialogs v-else-if="chats.length && !localLoading" @chatsLoad="chatsLoading = true" :chatsLoading="chatsLoading"
               :dialogs="filteredChats" @updatePages="updatePages" :newChats="newChats"/>

      <div class="chat-scripts" v-if="isScripts">
        <h4>Быстрые фразы</h4>
        <div class="chat-scripts__wrapper">
          <div class="chat-scripts__group" v-for="i in groupWithScripts" :key="i.id">
            <h5>{{i.name}}</h5>
            <div class="chat-scripts__item" v-for="s in i.scripts" :key="s.id" :title="s.text" @click="selectScript(s)">
              {{s.name}}
            </div>
          </div>
        </div>
      </div>
      <div class="chat-down">
        <div v-if="showUnreadFilter && !isScripts" class="chat-unread" @click="isUnread = !isUnread">
          {{ !isUnread ? 'Показать непрочитанные' : 'Показать все' }}
        </div>
        <div v-else-if="isScripts" class="chat-unread" @click="isScripts = false">
          Скрыть быстрые фразы
        </div>
        <ChatFilter v-if="showChatFilters" @update="updateFilters" :key="chatFilterKey"/>
      </div>
    </div>
    <div class="chat-empty-dialog" v-if="!chatSelected && !chatLoading">
      Выберите диалог
    </div>
    <div class="chat-empty-dialog" v-else-if="chatLoading">
      <v-progress-circular
          indeterminate
          color="#0E9888"
          size="50"
      ></v-progress-circular>
    </div>
    <router-view v-show="!chatLoading && chatSelected"/>
    <v-dialog
        v-model="createChatModal"
        max-width="612px"
    >
      <CreateUserChat :groupChat="groupChat" @update="getChatsByTab(tab)" @close="createChatModal = false"/>
    </v-dialog>
    <v-dialog
        v-model="removeReminderModal"
        max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Удаление напоминания
        </v-card-title>
        <v-card-text>Вы действительно хотите удалить напоминание?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="removeReminderModal = false"
          >
            Отмена
          </v-btn>
          <v-btn
              color="red"
              text
              @click="removeReminder"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Dialogs from "../../components/chat/Dialogs";
import {mapActions, mapState} from "vuex";
import CreateUserChat from "../../components/chat/CreateUserChat";
import {hasRight} from '@/utils/access/hasRight';
import {eventBus} from "@/main";
import ChatFilter from "@/components/chat/ChatFilter";

export default {
  name: "Chat",
  components: {ChatFilter, CreateUserChat, Dialogs},
  data: () => ({
    tab: 0,
    chatFilterKey: 0,
    tabs: ["Клиенты", "Сотрудники", "Напоминания"],
    search: '',
    searchScript: '',
    page: 1,
    perPage: 26,
    createChatModal: false,
    chatSelected: false,
    chatLoading: false,
    groupChat: false,
    newChats: [],
    timerId: null,
    chatsLoading: false,
    isUnread: false,
    areChatsMuted: false,
    removeReminderModal: false,
    localLoading: false,
    localAddLoading: false,
    selectedReminder: {},
    chatFilters: null,
    reminderTypes: [
      {
        value: 'today',
        title: 'Сегодня'
      },
      {
        value: 'tomorrow',
        title: 'Завтра'
      },
      {
        value: 'future',
        title: 'Будущие'
      },
      {
        value: 'outdated',
        title: 'Прошедшие'
      },
    ],
    selectedReminderId: null,
    remindersFiltered: [],
    isScripts: false,
  }),
  computed: {
    ...mapState("chat", ["chats", "reminders"]),
    ...mapState("common", ["loading",]),
    ...mapState("scripts", ["scriptGroups", "scripts"]),
    filteredChats() {
      let payload = this.chats;

      return payload.sort(function (a, b) {
        if (a.last_message && b.last_message)
          return new Date(b.last_message.created_at) - new Date(a.last_message.created_at);
      });
    },
    isPinnedChat() {
      return this.tab === 3;
    },
    isUserChat() {
      return this.tabs[this.tab] === "Сотрудники";
    },
    isClientChat() {
      return this.tabs[this.tab] === "Клиенты";
    },
    activeReminders() {
      if (this.reminders) {
        return this.reminders.today.length || this.reminders.tomorrow.length || this.reminders.future.length || this.reminders.outdated.length;
      }
      return false;
    },
    filteredScripts () {
      if (this.searchScript) {
        return this.scripts.filter(i => i.name.toLowerCase().includes(this.searchScript.toLowerCase()));
      }
      return this.scripts;
    },
    groupWithScripts () {
      let array = [];
      let scripts = [...this.filteredScripts];

      for (const s of scripts) {
        if (!array.some(i => i.id === s.script_group.id)) {
          array.push({
            name: s.script_group.name,
            id: s.script_group.id,
            scripts: [],
          });
        }
      }
      for (const i of array) {
        for (const s of scripts) {
          if (s.script_group.id === i.id) {
            i.scripts.push({
              name: s.name,
              attachments: s.attachments,
              text: s.text,
              id: s.id,
            })
          }
        }
      }
      return array;
    },
    showChatFilters () {
      return !this.isScripts && !this.isUserChat;
    },
    showUnreadFilter () {
      return this.isClientChat || this.isUserChat || this.isPinnedChat;
    }
  },
  methods: {
    ...mapActions({
      getChats: 'chat/getChats',
      setChatType: 'chat/setChatType',
      addChats: 'chat/addChats',
      getReminders: 'chat/getReminders',
      removeReminders: 'chat/removeReminders',
      getClientChat: 'chat/getClientChat',
      getClientStatuses: 'client-statuses/getStatusesBy',
      getTags: 'tags/getItemsBy'
    }),
    selectScript(i) {
      eventBus.$emit('selectScriptFromSide', i);
    },
    chat(id) {
      this.getClientChat(id).then((response) => {
        this.$router.push({path: `/chat/clients/${response.id}`}).catch(() => ({}));
      });
    },
    hasRight: hasRight,
    getReminder(item) {
      return this.reminders[item];
    },
    openRemoveReminderModal (id) {
      this.selectedReminderId = id;
      this.removeReminderModal = true;
    },
    removeReminder() {
      this.removeReminders({id: this.selectedReminderId, name: this.selectedReminder.value}).then(() => {
        this.removeReminderModal = false;
      });
    },
    updateFilters (filters) {
      this.chatFilters = filters;

      return this.getChatsByTab(this.tab, filters);
    },
    getChatsByTab(val, filters = null) {
      this.chatsLoading = false;
      this.page = 1;
      let args = {
        page: 1,
        per_page: this.perPage,
      };

      if (this.tabs[val] === "Клиенты") {
        this.localLoading = true;
        if (!this.hasRight('chat.accessAll') && !this.hasRight('chat.accessOwn')) {
          args.type = null;
        } else {
          args.type = 'outer';
        }
      } else if (this.tabs[val] === "Сотрудники") {
        this.localLoading = true;
        args.type = 'inner';
      } else if (this.tabs[val] === "Напоминания") {
        this.localLoading = false;
        return this.getReminders(filters);
      } else if (this.isScripts) {
        this.localLoading = false;
        return;
      } else {
        this.localLoading = true;
        args.type = 'pinned';
      }
      this.setChatType(args.type);

      if (this.search) {
        if (this.tabs[val] === "Клиенты")
          args['filter[client.name]'] = this.search;
        else if (this.tabs[val] === "Сотрудники")
          args['filter[chat]'] = this.search;
        else
          args['filter[chat]'] = this.search;
      }

      if (filters) {
        for (let key in filters) {
          args[key] = filters[key];
        }
      }

      if (this.isUnread) args['filter[unread]'] = true;

      this.getChats(args).finally(() => this.localLoading = false);
    },
    addChatsByTab(val) {
      let args = {
        page: this.page,
        per_page: this.perPage,
      };

      if (this.tabs[val] === "Клиенты") {
        args.type = 'outer';
      } else if (this.tabs[val] === "Сотрудники") {
        args.type = 'inner';
      } else {
        args.type = 'pinned';
      }

      if (this.chatFilters) {
        for (let key in this.chatFilters) {
          args[key] = this.chatFilters[key];
        }
      }

      if (this.search) {
        args['filter[name]'] = this.search;
      }

      if (this.isUnread) args['filter[unread]'] = true;

      this.addChats(args).then((res) => {
        this.newChats = res;
        if (res.length) {
          this.chatsLoading = false;
        }
      });
    },
    createChat(groupChat) {
      this.groupChat = groupChat;
      this.createChatModal = true;
    },
    updatePages() {
      this.page++;
      this.addChatsByTab(this.tab);
    },
    mute() {
      this.areChatsMuted = !this.areChatsMuted;
    },
  },
  mounted() {
    if (this.$route.path.includes('users')) {
      this.tab = this.tabs.indexOf('Сотрудники');
    } else {
      this.getChatsByTab(this.tab);
    }
    this.getReminders();
    this.getClientStatuses();
    this.getTags();
    this.areChatsMuted = localStorage.getItem("are_chats_muted") === "true";
    this.selectedReminder = this.reminderTypes[0];
    eventBus.$on('chatLoadingToggle', (value) => {
      this.chatLoading = value;
    });
  },
  watch: {
    tab(val) {
      this.page = 1;
      this.getChatsByTab(val);
      this.isScripts = false;
      this.chatFilterKey++;
    },
    '$route.params.id': {
      handler: function (val) {
        this.chatSelected = !!val;
      },
      deep: true,
      immediate: true
    },
    search() {
      if (this.timerId)
        clearTimeout(this.timerId);

      this.page = 1;
      this.timerId = setTimeout(() => {
        this.getChatsByTab(this.tab);
      }, 500);
    },
    areChatsMuted(v) {
      localStorage.setItem("are_chats_muted", v.toString());
    },
    isUnread () {
      this.getChatsByTab(this.tab);
    }
  }
}
</script>

<style lang="scss">
.chat {
  background: #FFFFFF;
  /*box-shadow: 3px 19px 39px rgba(0, 0, 0, 0.06);*/
  //border: 1px solid #EBEFF2;
  height: calc(100vh - 97px);
  display: flex;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  .chat-empty-all-dialogs {
    max-width: 321px;
    min-width: 321px;
    font-weight: 600;
    font-size: 14px;
    color: #5C5C5C;
    margin-top: 10px;
    padding-top: 20px;
    margin-left: 25px;
    max-height: $evi-chat-dialogs-height;
    min-height: $evi-chat-dialogs-height;
    overflow: hidden;
  }

  .chat-all-dialogs {
    max-width: 370px;
    min-width: 370px;
    padding-top: 5px;
    overflow: hidden;
    border-right: 1px solid #EBEFF2;
    position: relative;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px 5px 5px;
      border-bottom: 1px solid #EBEFF2;

      h3 {
        font-weight: 600;
        font-size: 16px;
        text-transform: uppercase;
        color: $evi-headline-color;
      }

      .evi-button-green {
        background: #45BF6A !important;
        border-radius: 50% !important;
      }
    }

    &__tabs {
      margin-top: 5px;
      padding: 0 15px;

      .open-scripts {
        margin-top: 13px;
        cursor: pointer;
      }

      .v-tab {
        color: $evi-headline-color;
        font-weight: 600;
        font-size: 12px;
        margin-left: 0 !important;
        letter-spacing: 0 !important;
        text-transform: none;
        padding: 0;
        margin-right: 17px;
        min-width: auto;
      }

      .v-slide-group__wrapper {
        overflow: visible !important;
      }
    }
  }

  .chat-empty-dialog {
    font-weight: 600;
    font-size: 14px;
    color: #5C5C5C;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
    background: transparent;
  }

  .chat-scripts {
    max-height: $evi-chat-dialogs-height;
    min-height: $evi-chat-dialogs-height;
    padding: 0 25px 25px;
    margin-top: 10px;
    overflow: auto;
    position: absolute;
    top: 112.4px;
    background: #ffffff;
    width: 100%;

    h4 {
      font-size: 14px;
      margin-bottom: 14px;
      text-transform: uppercase;
      font-weight: 600;
    }

    &__wrapper {
      overflow-y: auto;
      max-height: $evi-chat-scripts-height;
    }

    &__group {
      margin-bottom: 14px;

      h5 {
        font-size: 13px;
        margin-bottom: 8px;
      }
    }

    &__item {
      font-size: 13px;
      cursor: pointer;
      margin-bottom: 3px;
      color: #008A80;
    }
  }

  .chat-reminders {
    max-height: $evi-chat-dialogs-height;
    min-height: $evi-chat-dialogs-height;
    margin-top: 10px;

    &__headers {
      padding: 25px;
      padding-top: 0;
      border-bottom: 1px solid #EBEFF2;

      .reminder-type {
        margin-bottom: 8px;
        font-weight: 400;
        font-size: 12px;
        color: #717171;
        cursor: pointer;

        &.active {
          color: $evi-headline-color;
          font-weight: 600;
        }
      }
    }
  }

  .chat-has-reminders {
    display: block;
    position: absolute;
    content: '';
    width: 6.34px;
    height: 6.34px;
    background-color: #FF7969;
    border-radius: 50%;
    right: -7px;
    top: 12px;
  }

  .evi-accordion {
    //$evi-chat-window-height
    max-height: calc(100vh - 405px);
    .v-expansion-panel {
      border-bottom: 1px solid #EBEFF2;
      box-shadow: none !important;
      border-radius: 0 !important;

      .v-expansion-panel-header {
        flex-direction: row-reverse;
        padding-left: 8px;

        &__icon {
          margin-right: 7px;
        }

        &:focus:before {
          background-color: transparent !important;
        }
      }

      &:before {
        box-shadow: none !important;
      }

      &:after {
        display: none !important;
      }

      .accordion-name {
        font-weight: 600;
        font-size: 13px;
        text-decoration-line: underline;
        color: $evi-headline-color;
        max-width: 200px;
      }

      .accordion-info {
        &__date {
          font-weight: 400;
          font-size: 12px;
          color: $evi-grey-color;
          margin-bottom: 7px;
        }

        &__status {
          font-weight: 400;
          font-size: 12px;
          color: #2C3136;
          max-width: 150px;
        }
      }

      .accordion-text {
        font-weight: 400;
        font-size: 12px;
        color: $evi-headline-color;
        padding-left: 16px;
      }
    }
  }
  .chat-unread {
    width: 220px;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    text-decoration-line: underline;
    color: $evi-headline-color;
    padding: 15px 17px;
    cursor: pointer;
    background: #FFFFFF;
    z-index: 1;
    border-bottom-left-radius: 10px;
  }
  .chat-down {
    display: flex;
    width: 100%;
    border-top: 1px solid #EBEFF2;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 53px;
    &__filters {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      color: $evi-headline-color;
      padding: 10px 20px;
      width: 30%;
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-left: auto;
      height: 100%;
    }
    &__modal {
      left: 0 !important;
      bottom: 0 !important;
    }
  }
}
</style>
