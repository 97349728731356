<template>
  <v-card class="modal-create">
    <v-form ref="form" v-model="valid">
      <v-card-title class="d-flex justify-center">
        <span class="text-h5 text-center mb-11" v-if="!groupChat">Диалог с сотрудником</span>
        <span class="text-h5 text-center mb-11" v-else>Создание беседы</span>
      </v-card-title>
      <v-card-text class="modal-create__form">
        <div class="close-dialog" @click="closeDialog">
          <img :src="require('@/assets/img/common/close-dialog.svg')" />
        </div>
        <v-col
            cols="12"
        >
          <v-text-field
                  class="evi-text-field"
                  color="#44D370"
                  v-model="chatName"
                  :rules="chatNameRules"
                  v-if="groupChat"
          >
            <template v-slot:label>
              Название беседы
              <span class="important">*</span>
            </template>
          </v-text-field>
          <v-autocomplete
              class="evi-autocomplete"
              :multiple="groupChat"
              color="#44D370"
              v-model="selectedUsers"
              :items="filteredUsers"
              item-text="name"
              item-value="id"
              placeholder="Выберите сотрудника"
              :rules="userRules"
              :loading="userLoading"
              @focus="loadUsers"
              :search-input.sync="userSearch"
              @input.native="searchUser(userSearch)"
              cache-items
              clearable
              item-color="green"
          >
            <template v-slot:label>
              Сотрудники
              <span class="important">*</span>
            </template>
            <template v-slot:append-item>
              <div v-intersect="userIntersect" />
            </template>
            <template v-slot:item="{item}">
              <v-avatar
                  color="#FFFFFF"
                  size="30"
                  class="user-page__avatar mr-3"
              >
                <img
                    :src="item.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                    alt=""
                >
              </v-avatar>
              <div class="v-list-item__title">{{item.name}}</div>
            </template>
            <template v-slot:append>
                <span class="evi-select__arrow">
                    <v-icon>mdi-chevron-down</v-icon>
                </span>
            </template>
          </v-autocomplete>
        </v-col>
      </v-card-text>
      <v-card-actions class="modal-create__actions">
        <v-btn class="evi-button-green evi-button-green--fill" @click="create">
          Начать диалог
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {UserAutocompleteRequestsMixin} from "@/mixins/autocomplete/UserAutocompleteRequestsMixin";

export default {
  name: "CreateUserChat",
  mixins: [UserAutocompleteRequestsMixin],
  props: {
    groupChat: {
      type: Boolean,
    },
  },
  data: () => ({
    valid: true,
    userRules: [(v) => !!v || 'Выберите сотрудника'],
    chatNameRules: [(v) => !!v || 'Укажите название беседы'],
    selectedUsers: null,
    chatName: "",
  }),
  computed: {
    ...mapState("user", ["profile",]),
    filteredUsers () {
      let array = [];
      this.users.forEach(i => {
        if (i.id !== this.profile.id) {
          array.push(i);
        }
      });
      return array;
    },
  },
  methods: {
    ...mapActions({
      getUserChat: 'chat/getUserChat',
      createGroupChat: 'chat/createGroupChat',
    }),
    validate () {
      return !!this.$refs.form.validate();
    },
    create () {
      if (this.validate()) {
        if (!this.groupChat) {
          this.getUserChat(this.selectedUsers).then((response) => {
            this.$emit('update');
            this.closeDialog();
            this.$router.push({path: `/chat/users/${response.id}`});
          });
        } else {
          this.selectedUsers.push(this.profile.id);
          this.createGroupChat({
            name: this.chatName,
            users: this.selectedUsers,
          }).finally(() => {
            this.$emit('update');
            this.closeDialog();
            // this.$router.push({path: `/chat/users/${response.id}`});
          });
        }
      }
    },
    closeDialog () {
      this.$emit('close');
      this.selectedUsers = null;
      this.chatName = "";
      this.$refs.form.resetValidation();
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
